import React, { Fragment, useEffect, useState } from "react";
import { ListItem, Divider, ListItemText, IconButton, TextField, Container, MenuItem, Select, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import { getDir } from "orion-components/i18n/Config/selector";
import EditIcon from '@mui/icons-material/Edit';
import { getTranslation } from "orion-components/i18n";
import { useStyles } from "../styles/overrides";
import { formatPhone, countryCodes } from "../../utility";

const EditableField = ({ prop, index, lastIndex, editable, setValue, values, displayErrors, isMyUser, clear, countryCode, handleChangeCountryCode }) => {
    const classes = useStyles();

    const dir = useSelector((state) => getDir(state));

    const [isHovered, setIsHovered] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setIsEditing(false);
    }, [clear]);

    const styles = {
        leftText: {
            flex: "0 0 450px",
            alignItems: "center",
            paddingTop: 4,
            // paddingLeft: 24
            ...(dir === "rtl" ? { marginLeft: 24 } : { marginRight: 24 })
        },
        rightToLeft: {
            ...(dir === "rtl"
                ? {
                    paddingTop: 15,
                    paddingBottom: 16,
                    paddingLeft: 24,
                    paddingRight: 0,
                    textAlign: "right"
                }
                : { paddingLeft: 0 })
        },
        divider: {
            position: "absolute",
            left: 0,
            right: 0,
            opacity: 0.3
        },
        editIcon: {
            color: "#fff",
            fontSize: 21,
            transition: "0.2s"
        },
        userFieldStyles: {
            width: "45%"
        },
        inputLabelProps: {
            left: "unset",
            fontSize: 12,
            ...(dir && dir === "rtl" ? { transformOrigin: "top right" } : { transformOrigin: "top left" })
        },
        helperText: {
            ...(dir === "rtl" && {
                textAlign: "right",
                opacity: "1!important",
                position: "unset!important"
            })
        },
        flag: { height: 13, width: 25 }
    };

    const getField = () => {
        switch (prop?.id) {
            case "name":
                return (
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <TextField
                            variant="standard"
                            style={styles.userFieldStyles}
                            label={getTranslation("mainContent.accountSettings.editUser.firstName")}
                            error={displayErrors.name}
                            helperText={
                                displayErrors.name
                                    ? getTranslation("mainContent.accountSettings.editUser.errorMessage.emptyFieldErr")
                                    : ""
                            }
                            onChange={(e) => {
                                setValue(e.target.value, "firstName", values);
                            }}
                            value={values.firstName}
                            InputLabelProps={{
                                style: styles.inputLabelProps
                            }}
                            FormHelperTextProps={{
                                style: styles.helperText
                            }}
                            InputProps={{
                                classes: { underline: classes.underline },
                                style: { marginTop: 5, fontSize: 13 }
                            }}
                        />
                        <TextField
                            variant="standard"
                            style={styles.userFieldStyles}
                            label={getTranslation("mainContent.accountSettings.editUser.lastName")}
                            error={displayErrors.name}
                            helperText={
                                displayErrors.name
                                    ? getTranslation("mainContent.accountSettings.editUser.errorMessage.emptyFieldErr")
                                    : ""
                            }
                            onChange={(e) => {
                                setValue(e.target.value, "lastName", values);
                            }}
                            value={values.lastName}
                            InputLabelProps={{
                                style: styles.inputLabelProps
                            }}
                            FormHelperTextProps={{
                                style: styles.helperText
                            }}
                            InputProps={{
                                classes: { underline: classes.underline },
                                style: { marginTop: 5, fontSize: 13 }
                            }}
                        />
                    </div>
                )
            case "email":
                return (
                    <TextField
                        variant="standard"
                        style={{ ...styles.userFieldStyles, width: "35%" }}
                        type="email"
                        error={displayErrors.email}
                        helperText={
                            displayErrors.email
                                ? getTranslation("mainContent.accountSettings.editUser.errorMessage.validEmail")
                                : ""
                        }
                        onChange={(e) => {
                            setValue(e.target.value, "email", values);
                        }}
                        value={values.email}
                        InputLabelProps={{
                            style: styles.inputLabelProps
                        }}
                        FormHelperTextProps={{
                            style: styles.helperText
                        }}
                        InputProps={{
                            classes: { underline: classes.underline },
                            style: { fontSize: 13 }
                        }}
                    />
                )
            case "cell_phone":
                return (
                    <TextField
                        variant="standard"
                        style={{ ...styles.userFieldStyles, width: "25%" }}
                        error={displayErrors.cell}
                        helperText={
                            displayErrors.cell
                                ? getTranslation("mainContent.accountSettings.editUser.errorMessage.formatText")
                                : ""
                        }
                        onChange={(e) => {
                            setValue(formatPhone(e.target.value), "cell", values);
                        }}
                        value={values.cell || ""}
                        InputLabelProps={{
                            style: styles.inputLabelProps
                        }}
                        FormHelperTextProps={{
                            style: styles.helperText
                        }}
                        InputProps={{
                            classes: { underline: classes.underline },
                            style: { fontSize: 13 },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Select
                                        variant="standard"
                                        disableUnderline={true}
                                        value={countryCode?.cellPhone}
                                        onChange={(e) => handleChangeCountryCode(e.target.value, "cellPhone", countryCode)}
                                        classes={{
                                            select: classes.select
                                        }}
                                    >
                                        {countryCodes.map((countryCode) => {
                                            return <MenuItem value={countryCode.code}>
                                                <div style={styles.flag} className={`flag-icon-background ${"fi fi-" + countryCode.country.toLowerCase()}`} /></MenuItem>
                                        })}
                                    </Select>
                                </InputAdornment>
                            )
                        }}
                    />
                )
            case "office_phone":
                return (
                    <TextField
                        variant="standard"
                        style={{ ...styles.userFieldStyles, width: "25%" }}
                        error={displayErrors.office}
                        helperText={
                            displayErrors.office
                                ? getTranslation("mainContent.accountSettings.editUser.errorMessage.formatText")
                                : ""
                        }
                        onChange={(e) => {
                            setValue(formatPhone(e.target.value), "office", values);
                        }}
                        value={values.office || ""}
                        InputLabelProps={{
                            style: styles.inputLabelProps
                        }}
                        FormHelperTextProps={{
                            style: styles.helperText
                        }}
                        InputProps={{
                            classes: { underline: classes.underline },
                            style: { fontSize: 13 },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Select
                                        variant="standard"
                                        disableUnderline={true}
                                        value={countryCode?.officePhone}
                                        onChange={(e) => handleChangeCountryCode(e.target.value, "officePhone", countryCode)}
                                        classes={{
                                            select: classes.select
                                        }}
                                    >
                                        {countryCodes.map((countryCode) => {
                                            return <MenuItem value={countryCode.code}>
                                                <div style={styles.flag} className={`flag-icon-background ${"fi fi-" + countryCode.country.toLowerCase()}`} /></MenuItem>
                                        })}
                                    </Select>
                                </InputAdornment>
                            )
                        }}
                    />
                )
            case "password":
                return (
                    <Container
                        style={{
                            marginTop: "13px",
                            display: "flex",
                            padding: 0,
                            flexDirection: "column"
                        }}
                    >
                        {isMyUser && (
                            <TextField
                                variant="standard"
                                style={{
                                    maxWidth: 150
                                }}
                                label={getTranslation("mainContent.accountSettings.editUser.currentPass")}
                                type="password"
                                error={displayErrors.currentPassword}
                                helperText={displayErrors.currentPassword}
                                onChange={(e) => setValue(e.target.value, "currentPassword", values)}
                                value={values.currentPassword}
                                InputLabelProps={{
                                    style: styles.inputLabelProps
                                }}
                                FormHelperTextProps={{
                                    style: styles.helperText
                                }}
                                InputProps={{
                                    classes: { underline: classes.underline },
                                    style: { marginTop: 5, fontSize: 13 }
                                }}
                            />
                        )}
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 15 }}>
                            <TextField
                                variant="standard"
                                style={{
                                    maxWidth: 180
                                }}
                                label={getTranslation("mainContent.accountSettings.editUser.newPass")}
                                type="password"
                                error={displayErrors.newPassword || displayErrors.password}
                                helperText={displayErrors.newPassword || displayErrors.password}
                                onChange={(e) => setValue(e.target.value, "newPassword", values)}
                                value={values.newPassword}
                                InputLabelProps={{
                                    style: styles.inputLabelProps
                                }}
                                FormHelperTextProps={{
                                    style: styles.helperText
                                }}
                                InputProps={{
                                    classes: { underline: classes.underline },
                                    style: { marginTop: 5, fontSize: 13 }
                                }}
                            />
                            <TextField
                                variant="standard"
                                style={{
                                    maxWidth: 180
                                }}
                                label={getTranslation("mainContent.accountSettings.editUser.confirmPass")}
                                type="password"
                                error={displayErrors.password}
                                helperText={displayErrors.password}
                                onChange={(e) => setValue(e.target.value, "confirmPassword", values)}
                                value={values.confirmPassword}
                                InputLabelProps={{
                                    style: styles.inputLabelProps
                                }}
                                FormHelperTextProps={{
                                    style: styles.helperText
                                }}
                                InputProps={{
                                    classes: { underline: classes.underline },
                                    style: { marginTop: 5, fontSize: 13 }
                                }}
                            />
                        </div>
                    </Container>
                )
            case "org_name":
                return (
                    <TextField
                        variant="standard"
                        style={{
                            width: "60%"
                        }}
                        error={displayErrors.organizationName}
                        helperText={displayErrors.organizationName}
                        onChange={(e) => setValue(e.target.value, "organizationName", values)}
                        value={values.organizationName}
                        InputLabelProps={{
                            style: styles.inputLabelProps
                        }
                        }
                        FormHelperTextProps={{
                            style: styles.helperText
                        }
                        }
                        InputProps={{
                            classes: { underline: classes.underline },
                            style: { fontSize: 13 }
                        }
                        }
                    />
                )
            case "org_desc":
                return (
                    <TextField
                        variant="standard"
                        label={getTranslation(
                            "mainContent.manageOrganization.orgProfile.editOrganization.orgDescription",
                            values.organizationDescription.length
                        )}
                        error={displayErrors.organizationDescription}
                        helperText={displayErrors.organizationDescription}
                        value={values.organizationDescription}
                        style={{
                            width: "100%"
                        }}
                        InputProps={{
                            // disableUnderline: true,
                            style: {
                                // border: "1px solid #8b8d8f",
                                padding: 0
                                // backgroundColor: "#393d41"
                            },
                            inputProps: {
                                style: {
                                    padding: 0,
                                    fontSize: 13
                                }
                            }
                        }
                        }
                        onChange={(e) => {
                            if (e.target.value && e.target.value.length <= 50) {
                                setValue(e.target.value, "organizationDescription", values);
                            }
                        }}
                        InputLabelProps={{
                            style: styles.inputLabelProps
                        }}
                        FormHelperTextProps={{
                            style: styles.helperText
                        }}
                    />
                )
            default:
                break;
        }
    };

    const validatePrimaryText = (primary) => {
        switch (prop.id) {
            case "office_phone":
            case "cell_phone":
                const code = prop.id === "office_phone" ? countryCode?.officePhone : countryCode?.cellPhone;
                return `${code || ""} ${formatPhone(primary)}`
            default:
                return primary;
        }
    };

    const getRow = () => {
        return (<Fragment>
            <ListItemText
                primaryTypographyProps={{
                    className: "b1-white",
                    style: { fontSize: 13 }
                }}
                style={styles.middleText}
                primary={validatePrimaryText(prop.primary)}
            />
            {editable && isHovered && prop?.id &&
                <IconButton style={{ padding: 0, margin: 0 }} onClick={() => setIsEditing(true)}>
                    <EditIcon sx={styles.editIcon} />
                </IconButton>
            }
        </Fragment>)
    };

    return <>
        <Fragment key={`userProfileListItemFragment-${index}`}>
            <ListItem key={`userProfileListItem-${index}`} className={prop?.id === "password" && "pass_row"} style={styles.rightToLeft} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <ListItemText
                    className="b1-white"
                    style={styles.leftText}
                    primary={prop.label}
                    secondaryTypographyProps={{
                        className: "b1-light-gray"
                    }}
                    secondary={prop.secondary}
                />
                {isEditing ? getField() : getRow()}
            </ListItem>
            {index !== lastIndex && <Divider style={styles.divider} />}
        </Fragment >
    </>
};

export default EditableField;