import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { userService } from "client-app-core";

const CustomRedirect = ({ fromPath, toPath }) => {
	const location = useLocation();
	const [defaultApp, setDefaultApp] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const fetchDefaultApp = async () => {
		try {
			await new Promise((resolve, reject) => {
				userService.getAppSettings((err, result) => {
					if (err) {
						reject(err);
					} else {
						resolve(result);
					}
				});
			})
				.then((result) => {
					if (result?.defaultApp) {
						setDefaultApp(result.defaultApp);
					}
					setIsLoaded(true);
					setIsAuthenticated(true);
				})
				.catch((error) => {
					console.error("There was a problem with fetching the app settings:", error);
					setIsLoaded(true);
					setIsAuthenticated(false);
				});
		} catch (error) {
			console.error("There was a problem with fetching the app settings:", error);
			setIsLoaded(true);
			setIsAuthenticated(false);
		}
	};

	useEffect(() => {
		fetchDefaultApp();
	}, []);

	//Until the fetchDefaultApp function completes execution, nothing should happen in this component. That is why we are returning a fragment here.
	if (!isLoaded) {
		return <></>;
	}

	if (isAuthenticated && location.pathname === "/" && defaultApp !== "" && defaultApp !== null) {
		window.location.replace("/" + defaultApp + "/");
		return null;
	} else {
		if (!isAuthenticated) {
			window.location.replace("/login");
			return null;
		} else {
			return <Navigate from={fromPath} to={toPath} />;
		}
	}
};

export default CustomRedirect;
