import React, { memo } from "react";
import { Container, useMediaQuery } from "@mui/material";
import { getTranslation } from "orion-components/i18n";

const defaultProps = {
	children: [],
	headerTitle: getTranslation("mainContent.shared.articleContainer.defaultHeader"),
	headerDescription: "",
	headerStyles: {},
	headerDescStyles: {},
	titleStyles: {}
};

const ArticleContainer = ({
	editing,
	children,
	headerFullWidth,
	headerTitle,
	headerStyles,
	headerAdornment,
	headerDescription,
	headerDescStyles,
	headerOptionalSection,
	dir,
	id,
	titleStyles,
	dontShowHeader
}) => {
	const matchesLarge = useMediaQuery("(max-width:1023px) and (min-width:720px), (min-width:1048px)");
	const matchesSmall = useMediaQuery("(min-width:600px)");

	return (
		<Container
			id={id}
			style={{
				position: "relative",
				marginTop: 8,
				marginLeft: 24,
				marginRight: 24,
				...(matchesSmall
					? {
						marginTop: 16
					}
					: {}),
				...(matchesLarge
					? {
						flexBasis: "100%",
						flexGrow: 1,
						flexShrink: 1,
						marginLeft: "auto",
						marginRight: "auto",
						marginTop: 24,
						minWidth: 0
					}
					: {}),
				...(headerOptionalSection
					? {
						paddingTop: 30
					}
					: {}),
				borderRadius: 5,
				width: "100%",
				backgroundColor: "#292b30",
				padding: 40,
				maxWidth: 960
			}}
		>
			{!dontShowHeader ? (
				<header style={{ marginBottom: 20, ...headerStyles }}>
					<section
						style={{
							display: "flex",
							...(headerOptionalSection
								? {
									alignItems: "center"
								}
								: {})
						}}
					>
						<h3
							style={{
								width: headerFullWidth ? "100%" : "53%",
								display: "flex",
								fontSize: 18,
								...(headerOptionalSection ? { alignItems: "center" } : {}),
								...titleStyles
							}}
						>
							{headerTitle}
						</h3>
						{headerAdornment}
						{headerOptionalSection}
					</section>
					<div className="b2-bright-gray" style={{ fontSize: 12, ...headerDescStyles }}>
						{headerDescription}
					</div>
				</header>
			) : null}
			{children}
		</Container>
	);
};

ArticleContainer.defaultProps = defaultProps;
export default memo(ArticleContainer);
