import React, { memo } from "react";
// router
import { useNavigate } from "react-router-dom";
//material ui
import { Container, Divider, IconButton, useMediaQuery } from "@mui/material";

import { ArrowBack, ArrowForward } from "@mui/icons-material";

import { routes as r } from "../../routes.js";
import { getDir } from "orion-components/i18n/Config/selector.js";
import { useSelector } from "react-redux";

const defaultProps = {
	children: []
};
const EditPageTemplate = ({ children, title, subTitle, parentRoute }) => {
	const dir = useSelector((state) => getDir(state));

	const minWidth1024Query = useMediaQuery("(min-width:1024px)");
	const minWidth720Query = useMediaQuery("(min-width:720px)");
	const minWidth600Query = useMediaQuery("(min-width:600px)");
	const mixedQuery = useMediaQuery("(max-width:1023px) and (min-width:720px), (min-width:1048px)");

	const styles = {
		iconButton: {
			color: "white",
			...(dir && dir == "rtl" ? { marginLeft: 15 } : { marginRight: 15 })
		},
		mainContainer: {
			width: "100vw",
			height: "calc(100vh - 48px)",
			maxWidth: "unset",
			overflowY: "scroll",
			paddingTop: 52,
			paddingLeft: minWidth1024Query ? 48 : minWidth720Query ? 24 : minWidth600Query ? 16 : 8,
			paddingRight: minWidth1024Query ? 48 : minWidth720Query ? 24 : minWidth600Query ? 16 : 8,
			...(dir === "rtl" && { margin: "0 330px 0 0" }),
			...(dir === "ltr" && { margin: "0 0 0 330px" })
		}
	};

	return (
		<div style={{ display: "flex" }}>
			<Container
				style={styles.mainContainer}
			>
				<div style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "100px", maxWidth: "1120px", width: "100%" }}>
					<Container
						style={{
							maxWidth: "960px",
							paddingLeft: 0,
							paddingRight: 0,
							display: "flex",
							zIndex: 985,
							flexDirection: "column",
							alignItems: "center"
						}}
					>
						<header
							style={{
								alignItems: "center",
								display: "flex",
								maxWidth: "100%",
								minWidth: 0,
							}}
						>
							<h2>{title}</h2>
						</header>
						<div
							style={{
								alignItems: "center",
								display: "flex",
								maxWidth: "100%",
								minWidth: 0,
								color: "#828283",
								fontSize: 14,
								marginTop: 15
							}}
						>
							{subTitle}
						</div>
					</Container>
					{children}
				</div>
			</Container>
		</div>
	);
};
EditPageTemplate.defaultProps = defaultProps;
export default memo(EditPageTemplate);
